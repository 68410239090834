import styled from 'styled-components'

export const StyledContent = styled.div`
    ul {
        list-style: outside;
        margin: 0 0 1.45rem 1.45rem;
    }
    h2 {
        font-size: 18px;
    }
`
