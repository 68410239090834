import React from 'react'
import { useIntl } from 'gatsby-plugin-react-intl'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import { useStaticQuery, graphql } from 'gatsby'
//import { removeSlugDashes } from '../../utils'
import { PageHeaderWrap } from './page-header.style'

const PageHeader = ({ pageContext, location, title }) => {
    const intl = useIntl()
    let {
        breadcrumb: { crumbs },
    } = pageContext
    const disableLinks = [
        '/elements',
        '/category',
        '/profile',
        '/date',
        '/tag',
        '/page',
        '/blog',
        '/blog/page',
        '/blogs',
        '/services',
        '/license',
    ]
    const customCrumbLabel = location.pathname.toLowerCase()
    const crumbLabelArr = customCrumbLabel.split('/')
    const label = crumbLabelArr[crumbLabelArr.length - 1]
    const labelArr = label.split('-')
    const pageHeaderData = useStaticQuery(graphql`
        query pageHeaderQuery {
            file(relativePath: { eq: "images/bg/title-bar-01-bg.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1920, maxHeight: 400, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            site {
                siteMetadata {
                    supportedLanguages
                }
            }
        }
    `)
    const supportedLanguages =
        pageHeaderData.site.siteMetadata.supportedLanguages

    if (supportedLanguages.includes(crumbs[1].crumbLabel)) {
        // add language to home link
        crumbs[0].pathname = '/' + intl.locale
        // remove language from crumbs
        crumbs.splice(1, 1)
    }

    /*const translatedLabel = intl.formatMessage({
        id: `page.${removeSlugDashes(label)}.title`,
    })*/

    return (
        <PageHeaderWrap fluid={pageHeaderData.file.childImageSharp.fluid}>
            <Breadcrumb
                title={title}
                crumbs={crumbs}
                crumbLabel={labelArr.join(' ')}
                disableLinks={disableLinks}
            />
        </PageHeaderWrap>
    )
}

export default PageHeader
