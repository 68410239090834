import React from 'react'
import { graphql } from "gatsby";
import { useIntl } from 'gatsby-plugin-react-intl'
import SEO from '../../components/seo'
import Layout from '../../containers/layout/layout'
import Header from '../../containers/layout/header/header-custom'
import Footer from '../../containers/layout/footer/footer-custom'
import PageHeader from '../../components/pageheader'
import { Container, Row, Col } from '../../components/ui/wrapper'
import { StyledContent } from './style'

const LicenseTemplate = ({ pageContext, location, data }) => {
    const intl = useIntl()

    const licenseData = data.allLicense.nodes.filter(node =>
        node.lang.includes(intl.locale)
    )[0];

    return (
        <Layout location={location}>
            <SEO title={licenseData.title} />
            <Header />
            <PageHeader
                pageContext={pageContext}
                location={location}
                title={licenseData.title}
            />
            <Container pt="65px" pb="65px">
                <Row>
                    <Col>
                        <StyledContent
                            dangerouslySetInnerHTML={{
                                __html: licenseData.content
                            }}
                        />
                    </Col>
                </Row>
            </Container>
            <Footer />  
        </Layout>
    )
}

export const licenseQuery = graphql`
    query ($slug: String!) {
        allLicense(filter: { slug: { eq: $slug } }) {
            nodes {
                id
                title
                slug
                lang
                content
            }
        }
    }
`;

export default LicenseTemplate
